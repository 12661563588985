/*
Theme Name: it-site
author: tomoya takamori
*/

//@import "reset/reset";
@import "setting/setting";

@import "bourbon/bourbon";
//@import "base/base";
@import "neat/neat";


@import"bootstrap/bootstrap-theme";
@import"bootstrap/bootstrap";
/*

@import "fontawesome/fontawesome";
@import "fontawesome/brands";
@import "fontawesome/regular";


@import "drawer/drawer";
@import "slick/slick";
@import "slick/slick-theme";
@import "lightbox/lightbox";
*/
@import "layout/layout";
