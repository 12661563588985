//プラン　ディスプレイのリンクボタンを中央に持ってくる
.p-plan__meta-btn{
  margin: 20px auto 0px;

}


//
a :hover{
  text-decoration: none;

}

.p-info__text span {
    display: block;
    font-size: 34px;
    line-height: 0.9;
    margin-top: 8px;
    margin-bottom: 9px;
}
.p-info__text{
  line-height: 2.2;
}
.p-info__text br{
  display: none;
}


//get_footer
.p-footer-nav{
  @include media($middle){
    display: none !important;
  }
}

//トッップのコンセプトをスマフォでサイズを小さく
.p-section-header__headline-inner{
  @include media($mobile){
    font-size: 1.4rem;
  }

}



//トップのスライドの文字にドロップシャドーを追加
.p-index-slider__item-title, .p-index-slider__item-desc{
  color: #fff !important;
  text-shadow:1px 3px 6px #3e3e3e !important;
}

.p-index-slider__item-desc{
  font-family: "Times New Roman", "游明朝", "Yu Mincho", "游明朝体", "YuMincho", "ヒラギノ明朝 Pro W3", "Hiragino Mincho Pro", "HiraMinProN-W3", "HGS明朝E", "ＭＳ Ｐ明朝", "MS PMincho", serif !important;
}
