.contact{
  .form-group dl{
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
    display: table;
    @include media($middle){
      display: block;
    }
    dt{
      display: table-cell;
      vertical-align: middle;
      width: 400px;
      text-align: center;
      @include media($middle){
        display: block;
        width: 100%;
      }
      label{
        font-weight: bold;
        padding: 0px;
      }
    }
    dd{
      display: table-cell;
      width: 780px;
      @include media($middle){
        display: block;
        width: 100%;
        margin: 0px;
      }
      .form-control{
        width: 100%;
      }
      .wpcf7-list-item{
        display: inline-block;
        @include media($middle){
          display: block;
          position: relative;
        }
      }
      .wpcf7-list-item-label{
        @include media($middle){
          display: block;
          width: 100%;
        }
      }
      label{
        line-height: 1.3;
      }
    }
  }
}
