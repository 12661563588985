@charset "utf-8";
@import "setting";
@import "gallery";

@import "component";
@import "contact";

/*
@import "archive";
@import "single";
@import "sidebar";
@import "sidebar-left";

@import "page-single";



@import "top";
@import "company";
@import "business";

@import "global";
*/
//@import "header";
//@import "footer";
